import Cookies from 'js-cookie'; // eslint-disable-line

export const CookieManager = () => {};
export const TRUTHY = true;
export const FALSY = false;
export const COOKIE_CHOICE_MADE = 'choiceMade';
export const COOKIE_RECORD_GAME_PROGRESS = 'recordGameProgress';
export const COOKIE_GLOBAL_SETTINGS = {
  expires: 365
};
export const COOKIE_MANAGER_COOKES = [
  COOKIE_CHOICE_MADE,
  COOKIE_RECORD_GAME_PROGRESS
];
export const GA_COOKIES = [
  '_gat',
  '_ga',
  '_gid'
];

CookieManager.launchGA = () => {  // eslint-disable-line
  const gaId = 'G-M1CVW2K69E';
  const gaScript = document.createElement('SCRIPT');

  gaScript.type = 'text/javascript';
  gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  const script2 = document.createElement('SCRIPT');

  script2.type = 'text/javascript';

  script2.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${gaId}');`;
  const body = document.querySelector('body');
  body.appendChild(gaScript);
  body.appendChild(script2);
};

CookieManager.setUpCookies = (cookieNames = [COOKIE_CHOICE_MADE, COOKIE_RECORD_GAME_PROGRESS]) => {
  cookieNames.forEach((cookieName) => {
    if (typeof Cookies.get(cookieName) === 'undefined') {
      Cookies.set(cookieName, FALSY, COOKIE_GLOBAL_SETTINGS);
    }
  });
};

CookieManager.hasChoiceBeenMade = () => {
  CookieManager.setUpCookies();
  return Cookies.get(COOKIE_CHOICE_MADE) === String(TRUTHY);
};

CookieManager.playWithSaveData = () => {
  Cookies.set(COOKIE_CHOICE_MADE, TRUTHY, COOKIE_GLOBAL_SETTINGS);
  Cookies.set(COOKIE_RECORD_GAME_PROGRESS, TRUTHY, COOKIE_GLOBAL_SETTINGS);
};

CookieManager.removeGACookies = (cookies = GA_COOKIES) => {
  cookies.forEach((cookieName) => {
    Cookies.set(cookieName, '', COOKIE_GLOBAL_SETTINGS);
    Cookies.remove(cookieName);
  });
};

CookieManager.playWithOutSaveData = () => {
  Cookies.set(COOKIE_CHOICE_MADE, TRUTHY, COOKIE_GLOBAL_SETTINGS);
  Cookies.set(COOKIE_RECORD_GAME_PROGRESS, FALSY, COOKIE_GLOBAL_SETTINGS);
  CookieManager.removeGACookies();
};

CookieManager.resetCookies = () => {
  Cookies.set(COOKIE_CHOICE_MADE, FALSY, COOKIE_GLOBAL_SETTINGS);
  Cookies.set(COOKIE_RECORD_GAME_PROGRESS, FALSY, COOKIE_GLOBAL_SETTINGS);
};

CookieManager.playingWithSavedData = () => Cookies
  .get(COOKIE_RECORD_GAME_PROGRESS) === String(TRUTHY);

CookieManager.getCookieValues = () => {
  const values = {};
  COOKIE_MANAGER_COOKES.forEach((key) => {
    console.log(`${key}: ${Cookies.get(key)}`);
    values[key] = Cookies.get(key);
  });
  return values;
};

window.CookieManager = CookieManager;

export default CookieManager;
